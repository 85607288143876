























































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import ConfirmCancelRequestView from
  '@/vue-app/view-models/allianz-dashboard/allianz-actions/pending-requests/confirm-cancel-request-view-model';

// Domain
import {
  PendingTransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/pending-transaction-entity';

@Component({
  name: 'RequestsDialog',
  filters: { currencyFormat },
})
export default class RequestsDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('request', { type: Object, required: true })
  synced_request!: PendingTransactionEntity;

  view_model = Vue.observable(new ConfirmCancelRequestView(this));

  back() {
    this.synced_is_open = false;
  }
}
